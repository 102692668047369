import React, { useState, useCallback } from "react";
import { ValidatedForm, OKCancel } from "../lib/iprs-react-library/src/index.js";


import fieldDefinitions from "../Data/Form.json";


function IssueForm({api, onSuccess, onCancel}) {



    const [message, setMessage] = useState("Submit");   
    const [error, setError] = useState('');
    const [formFieldData, setFormFieldData] = useState({
        
    });
    const [formFieldsInternal, setFormFieldsInternal] =
        useState(fieldDefinitions);
    const [formIsValid, setFormIsValid] = useState(false);  
    const [validating, setValidating] = useState(false);
    
    const submit = useCallback(
        () => {
           api.transact("SaveIssue", formFieldData )
                    .then(r => {
                                            
                       onSuccess(r.apiResult);
                                           
                    })
                    .catch(err => {
                        setError(err.message);
                        setMessage("Submit");
                    }); 
        },
        [api, formFieldData]
    );

    const cancelForm = () => {
      
      onCancel('menu');

    };

  return (
    
    <form>
        <h2>Submit a ticket</h2>
        
        <p>Before you submit...</p>
        <ul>
            <li>Has anybody else in your team already submitted a ticket regarding your issue? Duplicate requests slow us down...</li>
            
        </ul>
        
        <ValidatedForm 
          api={api}
          formFieldData={formFieldData}
          setFormFieldData={setFormFieldData}
          fieldDefinitions={formFieldsInternal}
          setDataIsValid={setFormIsValid}
          validating={validating}
          />
        <hr />
        <div class="button-holder">
            <OKCancel
                onOK={submit}
                onCancel={cancelForm}
                okText={message}
                enabled={!validating || formIsValid}
            />
        </div>
        <a href="/mytickets">Return to my ticket list</a>
    </form>
      
  );
}

export default IssueForm;
