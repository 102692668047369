import React, { useState, useCallback, useEffect } from "react";
import { UnValidatedForm, OKCancel } from "../lib/iprs-react-library/src/index.js";

import { useParams } from 'react-router-dom';

import fieldDefinitions from "../Data/Form.json";

import Comment from './Comment.jsx';
import NewComment from './NewComment.jsx';

import SimpleFileUpload from "../lib/iprs-react-library/src/Form/Fields/SimpleFileUpload";

function IssueUpdate({api}) {

    const { id } = useParams();
    const [issue, setIssue] = useState();
    const [attachments, setAttachments] = useState([]);
    const [comments, setComments] = useState([]);
    const [comment, setComment] = useState('');
    const [submittedBy, setSubmittedBy] = useState('');
  
    const [formFieldsInternal, setFormFieldsInternal] =
        useState(fieldDefinitions.filter(filterFormData).map(editFormData));

    
    useEffect(() => {
        getTicket(id);
    }, [id]);
  

    function editFormData(fieldDefinitions)
    {

        fieldDefinitions.required = false;
        fieldDefinitions.controlType = 'TextBox';
        return fieldDefinitions;
    }

    function filterFormData(fieldDefinitions)
    {
        if(fieldDefinitions.controlType === 'FileUpload')
            return false;

        return true;
    }

    const handleFileUpload = (r) => { 

         let files = r.target.value;
         files.forEach((file) => 
         {           
             file.JiraID = id;
            AttachFile(file);
         });
         
     };
  
    function AttachFile(file) {
      api.transact("AttachFile", file
        )
        .then(r => {
           getTicket(id);
        })
        .catch(err => {

        }); 
  }
  
  function getTicket(issueId) {
      api.transact("GetIssue", {
          "issueId": id
            }
        )
        .then(r => {
            setIssue(r.apiResult.Fields);
            setAttachments(r.apiResult.Attachments);
            setComments(r.apiResult.Comments);
            setSubmittedBy(r.apiResult.SubmittedBy);
            console.log(r);
        })
        .catch(err => {

        }); 
  }
  
    function AddComment() {
      api.transact("AddComment", {
          "id": id,
          "comment": comment
            }
        )
        .then(r => {

            console.log(r);
        })
        .catch(err => {

        }); 
  }

  return (
    
    <>
    {issue != null ? 
    <div>
        <h2>Ticket #{id}</h2>
        <h3>Sumbitted by {submittedBy}</h3>
        <hr />
        <form>
            <UnValidatedForm 
              api={api}
              formFieldData={issue}
              setFormFieldData={setIssue}
              fieldDefinitions={formFieldsInternal}
                validating={true}
                    onChange={()=>null}
            
              />
            <hr />

            <h2>Attachments</h2>

            {attachments.map(att => (<div style={{marginBottom:'15px', borderLeft:'1px solid black', paddingLeft:'5px'}}><p>Uploaded: {att.Created}</p><a href={att.URL} target="_blank">{att.name}</a><br /></div>))}
            
            <SimpleFileUpload handleChange={handleFileUpload} api={api} name="FileUpload" text="Attach a new file" noFileUpload={false} multiple={true} />
            
            <hr />
            
            <h2>Comments</h2>
            
            <NewComment value={comment} setValue={setComment} />
            
            <div class="button-holder">
                <div class="wrapper">
                <button onClick={AddComment}>Add Comment</button>
                </div>
            </div>
            {comments.map(att => (<Comment comment={att} />))};
            
            <hr />
            
            <a href="/mytickets">Return to my ticket list</a>

        </form>
    </div>
    : <p>loading</p>
    }
    
    </>
      
  );
}

export default IssueUpdate;
