


import React, { useState, useCallback } from "react";

function Comment({comment}) {

    

  return (
    
            <div style={{border:'1px solid black', padding:'10px', marginBottom:'10px', overflow:'scroll'}}>
                <p>User: {comment.Author}</p>
                <pre>{comment.Body}</pre>
                <p>Last Updated: {comment.LastUpdated}</p>
            
            </div>
      
  );
}

export default Comment;
