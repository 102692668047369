import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import API from "iprs-api-library";
import { getURLPathArray, getURLSearchParam, removeURLSearchParam } from "./lib/iprs-react-library/src/index.js";

const root = ReactDOM.createRoot(document.getElementById('root'));

const apiURL        = process.env.REACT_APP_IPRS_WEB_API_URL;
const fileUploadURL = process.env.REACT_APP_IPRS_FILE_UPLOAD_URL;
const api           = new API(null, apiURL, fileUploadURL);

const urlToken = getURLSearchParam("Token") ?? getURLSearchParam("token");
// better not to keep these in the address bar
removeURLSearchParam('Token');
removeURLSearchParam('token');
const urlPath = getURLPathArray();

if (urlToken) {
    api.setToken(urlToken);
}

root.render(
  <React.StrictMode>
    <BrowserRouter>
        <App api={api} urlToken={urlToken} urlPath={urlPath} />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
