import { React, useState, useCallback, useEffect } from "react";
import { Routes, Route } from 'react-router-dom';
import "./css/style.css";


import Main from './Components/Main';
import IssueUpdate from './Components/IssueUpdate'
import TicketList from './Components/TicketList'

import { 
    LoginOverlay,
    TwoSwitch
} from "./lib/iprs-react-library/src/index.js";


const App = ({ api, urlPath, urlToken }) => {
    
    const [loggedIn, setLoggedIn] = useState(api.isLoggedIn());
    
    // 2. If token didn't get validated, or URL token is non existent, 'LoginOverlay' component
    //    will be displayed and only when 'loggedIn' state is true, it will switch to 'ReferrerDetails'
    //    component.
    const handleLoginEvent = useCallback(
        action => {
            const isLoggedIn = "Logged in" === action;
            setLoggedIn(isLoggedIn);
        },
        [setLoggedIn]
    );
    
     // 1. When the app starts, it will check to validate the token if
    //    URL contains token using the 'getURLSearchParam' component from the 'iprs-react-library'.
    //    When it is authenticated, 'loggedIn' state will be set to true.

    useEffect(() => {
        console.log('logged in: '+loggedIn);
        if (urlToken) {
            api.validateLoggedIn(urlToken) // check existing stored
                .then(token => {
                    setLoggedIn(true);
                    api.setToken(''+token);
                })
                .catch(e => {
                                   
                    localStorage.setItem("Token", null);
                });
        }
    }, [api, urlToken]);
    
    return (
      <div class="main__section">
          <div className="container typography">
            <TwoSwitch test={!loggedIn}>
            <div><p>Please use your Pharos credentials to continue.</p>
                <LoginOverlay
                    handleLoginEvent={handleLoginEvent}
                    api={api}
                >
                    {{
                        legend: "", // don't want anything for WR
                        usernameLabel: "Email"
                    }}
                    </LoginOverlay></div>
                <Routes>
                    <Route path="/" element={<Main api={api} />} />
                    <Route path="/issue/:id" element={<IssueUpdate  api={api} />} />
                    <Route path="/mytickets" element={<TicketList   api={api} />} />
                </Routes>

            </TwoSwitch>
          </div>
      </div>
    );
}

export default App;
