


import React, { useState, useCallback, useEffect } from "react";

function TicketList({api}) {

    const [tickets, setTickets] = useState([]);
   
    useEffect(() => {
        api.transact("GetUserTickets" )
            .then(r => {

               setTickets(r.apiResult);

            })
            .catch(err => {

        }); 
    },[api]);
    

    return (
    
        <div>

            <h2>My Tickets</h2>
            
            <table style={{padding:'10px'}}>
            <thead>
                <tr>
                <td>ID</td>
                <td>Link</td>
                <td>Status</td>
                </tr>
             </thead>
             <tbody>

            {tickets.map(att => (
                    
            <tr>
                <td>{att.JiraID}</td>
                <td><a href={"/issue/" + att.JiraID}>{att.JiraID + " - " + att.Summary}</a></td>
                <td>{att.Status}</td>                      
            </tr>
                       
            ))}
   
                        
            </tbody>
                       
            </table>
            
            <hr />
            <a href="/">Return to menu</a>
            
           
        </div>
      
    );
}

export default TicketList;
