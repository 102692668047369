import React, { useState, useCallback } from "react";

function NewComment({value, setValue}) {

    const handleChange = (e) => setValue(e.target.value);

    return (

              <textarea value={value} onChange={handleChange} cols="85" rows="10"></textarea>

    );
}

export default NewComment;