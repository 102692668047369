import React, { useState, useCallback } from "react";

function Menu({onSelection}) {

  return (
    
            <div>
            
                <h1>What do you want to do?</h1>
            

                <div className="option" onClick={() => onSelection('ticket') }>

                    <h3>Submit a ticket</h3>
                    <p>Use this if you have an issue with an existing system</p>

                </div>
                <div className="option" onClick={() => onSelection('list') }>

                    <h3>View my tickets</h3>
                    <p>Click here to see all of your tickets to date</p>

                </div>
                <div className="option" onClick={() => onSelection('change') }>

                    <h3>Change Request</h3>
                    <p>Use this if you want to request a business change</p>

                </div>
                
            </div>
      
  );
}

export default Menu;