import React, { useState, useCallback } from "react";
import { ValidatedForm, OKCancel } from "../lib/iprs-react-library/src/index.js";

import TicketList from './TicketList'
import IssueForm from './IssueForm';
import Menu from './Menu';
import { useNavigate } from 'react-router-dom';


function Main({api}) {

    const [currentState, setCurrentState] = useState('menu');

    const navigate = useNavigate();
    const handleClick = (r) => { 
        console.log(r);
        let url = '/issue/' + r.id;
        console.log(url);
        navigate(url);
    };
    
    const menuChange = (destination) => {
        
        if(destination === 'change')
        {
            let url = 'https://forms.office.com/e/eZHjxcPAUL';
            console.log(url);
            window.location.href = url;
        }
        else
        {
            setCurrentState(destination);
        }
    };
    

    const renderSwitch = () => {
        switch(currentState) {
            case 'menu':
            return <Menu onSelection={menuChange}></Menu>;
        case 'list':
            return <TicketList   api={api} />;
        default:
            return <IssueForm api={api} onSuccess={handleClick} onCancel={menuChange}></IssueForm>;
    }
    };

  return (
    <div>
        {renderSwitch()}
    </div>
  );
}

export default Main;
